/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()

  return (
    <>
      {/* <SidebarMenuItem
          to='/my-page'
          title='Обзор'
          icon='home'
          fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
          //to='/report-page'
          to={`/report-page/?login=${currentUser?.email}`}
          title='КПСС'
          icon='chart-pie-4'
          fontIcon='bi-app-indicator'
      />
      {/*<SidebarMenuItem*/}
      {/*    //to='/bidder-page'*/}
      {/*    to={`/bidder-page/?login=${currentUser?.email}`}*/}
      {/*    title='Биддинг'*/}
      {/*    icon='wrench'*/}
      {/*    fontIcon='bi-app-indicator'*/}
      {/*/>*/}
      <SidebarMenuItem
          //to='/relevant-page'
          to={`/relevant-page/?login=${currentUser?.email}`}
          title='Релевантные лендинги'
          icon='abstract-25'
          fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
          //to='/relevant-ads-page'
          to={`/relevant-ads-page/?login=${currentUser?.email}`}
          title='Релевантность объявлений'
          icon='abstract-21'
          fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
          //to='/summary-report'
          to={`/summary-report/?login=${currentUser?.email}`}
          title='Сводный отчет'
          icon='graph-up'
          fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
          to='/optimization-page'
          title='Статистика'
          icon='chart-simple-2'
          fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
          to='/bilder-page'
          title='Рекомендации'
          icon='like'
          notification="1"
          fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
          to='/my-page1'
          title='Библиотека минус-слов'
          icon='note-2'
          fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
          to='/my-page2'
          title='Информация'
          icon='message-question'
          fontIcon='bi-app-indicator'
      /> */}










{/*
      <SidebarMenuItemWithSub
        to='/my-page'
        title='Статистика'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/report-page' title='Отчет о КПСС'  />
        <SidebarMenuItem to='/optimization-page' title='Отчет о биддинге' />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/my-page'
        title='Оптимизация'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/optimization-page' title='Оптимизация КПСС'  />
        <SidebarMenuItem to='/bilder-page' title='Биддинг (оптимизация ставок)' />
      </SidebarMenuItemWithSub> */}





{/*
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

    </>
  )
}

export {SidebarMenuMain}
