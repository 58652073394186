/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'
import {Tooltip} from '../../../layout/components/tooltip'
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'
import  DatePicker, { registerLocale } from "react-datepicker"
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox'

import axios from 'axios'
import * as XLSX from 'xlsx'
import ru from "date-fns/locale/ru";
import {useAuth} from '../../../../app/modules/auth'
import {addDays} from 'date-fns'
import {addToDataLayer, sendMetricGoalVisitParametr, sendMetricVisitParametr} from '../../../../utils/metrics'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import {translateRDG} from '../../../../translations/ru/translation'
registerLocale("ru", ru); // register it with the name you want

type Props = {
  className: string
}

interface SRDataItem {
  profile_name: string;
  update_date: string;
  kpss_from_impressions: any;
  kpss_from_clicks: number;
  rel_title_from_impressions: number;
  rel_title_from_clicks: number;
  rel_text_from_impressions: number;
  rel_text_from_clicks: number;
  rel_yandex_from_clicks: number;
  rel_google_from_clicks: number;
  autotarget_rate_part: number;
  search_autotarget: number;
  search_other: number;
  network: number;
  value?: string;
  label?: string;
}

const TablesWidget19: React.FC<Props> = ({className}) => {

  const API_URL = process.env.REACT_APP_NGRAM_API_URL

  const [profileId, setProfileId] = useState(localStorage.getItem('profileId') || "")

  const {currentUser, logout} = useAuth()

  const [selectFocus, setSelectFocus] = useState(4)
  const onBlur = () => setSelectFocus(4)
  const gridStyle = { minHeight: 740, zIndex: selectFocus }
  const [activateRowOnFocus, setActivateRowOnFocus] = useState(true)
  const pleaseWait = <b>Загрузка данных, подождите ... </b>

  const columnsReport = [
    { name: 'id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
    { name: 'profile_name', defaultFlex: 1, minWidth: 300, header: 'Аккаунт директа',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column '>
          {/*<span className='text-muted fw-semibold text-muted d-block fs-8'>*/}
          {/*  {value[0]}*/}
          {/*</span>*/}
          <span className='text-dark fw-bold fs-8'>
            {value}
          </span>
        </div>
    },
    { name: 'update_date',defaultFlex: 1,  minWidth: 90, header: 'Дата обновления', type: 'number' },
    { name: 'kpss_from_impressions', defaultFlex: 1,  minWidth: 180, header: 'КПСС через показы', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}

            /> }
          </div>
        </div>
    },
    { name: 'kpss_from_clicks',defaultFlex: 1,  minWidth: 180, header: 'КПСС через клики', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'rel_title_from_impressions',defaultFlex: 1,  minWidth: 190, header: 'Рел. заголовков через показы', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'rel_title_from_clicks',defaultFlex: 1,  minWidth: 180, header: 'Рел. заголовков через клики', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'search_autotarget',defaultFlex: 1,  minWidth: 180, header: 'Поиск Автотаргетинг %', type: 'number', style: {background: 'rgb(252 255 136 / 20%)'}, filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'search_other',defaultFlex: 1,  minWidth: 180, header: 'Поиск Остальное %', type: 'number', style: {background: 'rgb(252 255 136 / 20%)'}, filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'network',defaultFlex: 1,  minWidth: 180, header: 'Сеть %', type: 'number', style: {background: 'rgb(252 255 136 / 20%)'}, filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'rel_text_from_impressions',defaultFlex: 1,  minWidth: 130, header: 'Рел. текста через показы', type: 'number',
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'rel_text_from_clicks',defaultFlex: 1,  minWidth: 130, header: 'Рел. текста через клики', type: 'number',
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'rel_yandex_from_clicks',defaultFlex: 1,  minWidth: 180, header: 'Рел. лендингов по Яндекс', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
          </div>
          {(() => {
            if (Number(value) <= 75 && String(value) != "null") {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else if (Number(value) > 75) {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else {
              return (
                <div className='h-6px mw-150px'><div /></div>
              )
            }
          })()}

          {/*<div className='progress h-6px mw-150px'>*/}
          {/*  {Number(value) <= 75 ? <div*/}
          {/*    className='progress-bar bg-danger'*/}
          {/*    role='progressbar'*/}
          {/*    style={{width: `${value}%`}}*/}
          {/*  /> : <div*/}
          {/*    className='progress-bar bg-success'*/}
          {/*    role='progressbar'*/}
          {/*    style={{width: `${value}%`}}*/}
          {/*  /> }*/}
          {/*</div>*/}
        </div>
    },
    { name: 'rel_google_from_clicks',defaultFlex: 1,  minWidth: 180, header: 'Рел. лендингов по Google', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
          </div>
          {(() => {
            if (Number(value) <= 75 && String(value) != "null") {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else if (Number(value) > 75) {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else {
              return (
                <div className='h-6px mw-150px'><div /></div>
              )
            }
          })()}

          {/*<div className='progress h-6px mw-150px'>*/}
          {/*  {Number(value) <= 75 ? <div*/}
          {/*    className='progress-bar bg-danger'*/}
          {/*    role='progressbar'*/}
          {/*    style={{width: `${value}%`}}*/}
          {/*  /> : <div*/}
          {/*    className='progress-bar bg-success'*/}
          {/*    role='progressbar'*/}
          {/*    style={{width: `${value}%`}}*/}
          {/*  /> }*/}
          {/*</div>*/}
        </div>
    },
    // { name: 'autotarget_rate_part', defaultFlex: 1, minWidth: 120, header: 'Доля расхода с автотаргетинга', type: 'number',
    //   render: ({ value }) =>
    //     <div className='d-flex flex-column w-100 me-2'>
    //       <div className='d-flex flex-stack mb-2'>
    //         <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
    //       </div>
    //       {(() => {
    //         if (Number(value) <= 25 && String(value) != "null") {
    //           return (
    //             <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
    //           )
    //         } else if (Number(value) > 25) {
    //           return (
    //             <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
    //           )
    //         } else {
    //           return (
    //             <div className='h-6px mw-150px'><div /></div>
    //           )
    //         }
    //       })()}
    //     </div>
    // },
  ]

  const nowDate = new Date()
  const [startDate, setStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
  const [endDate, setEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), 0)) //useState(nowDate)

  const [minDate, setMinDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
  const [maxDate, setMaxDate] = useState(nowDate)

  const [availableStartDate, setAvailableStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
  const [availableEndDate, setAvailableEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)) //useState(nowDate)

  const [autotargetDateFrom, setAutotargetDateFrom] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
  const [autotargetDateTo, setAutotargetDateTo] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1))

  const [autotarget, setAutotarget] = useState("keywords")

  const [metrika, setMetrika] = useState("clicks")

  const [defaultValueSearchSummaryReport, setDefaultValueSearchSummaryReport] = useState<SRDataItem[]>([])

  const [defaultValueAutotarget, setDefaultValueAutotarget] = useState({ value: localStorage.getItem('profileAutotargetValue'), label: localStorage.getItem('profileAutotargetLabel') })

  const [defaultValueMetrics, setDefaultValueMetrics] = useState({ value: localStorage.getItem('profileMetricsValue'), label: localStorage.getItem('profileMetricsLabel') })

  const [selectedOptionSearchSummaryReport, setSelectedOptionSearchSummaryReport] = useState(defaultValueSearchSummaryReport)

  const [selectedOptionAutotarget, setSelectedOptionAutotarget] = useState(defaultValueAutotarget)

  const [selectedOptionMetrics, setSelectedOptionMetrics] = useState(defaultValueMetrics)

  const [dateForReport, setDateForReport] = useState(true)

  const [buttonSendReport, setButtonSendReport] = useState(true)

  const [buttonResetFilters, setButtonResetFilters] = useState(true)

  const handleChangeDate = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    setMinDate(newStartDate)
    const futureDate = addDays(newStartDate, 59)
    setMaxDate(futureDate > nowDate ? nowDate : futureDate)
    if (newStartDate === null || newEndDate === null) {
      setDateForReport(false)
    } else {
      setDateForReport(true)
      localStorage.setItem('profileDateFrom', `${newStartDate.toLocaleDateString('fr-CA')}`)
      localStorage.setItem('profileDateTo', `${newEndDate.toLocaleDateString('fr-CA')}`)
      setButtonResetFilters(false)

      sendMetricGoalVisitParametr('reachGoal', 'filter_calendar_summary-report', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_calendar_summary-report": {}
          }
        }
      })
    }
    //console.log(newStartDate.toLocaleDateString('fr-CA'))
    //console.log(newEndDate.toLocaleDateString('fr-CA'))
  }

  const handleMetrics = (selectedOption) => {
    if (selectedOption === null || selectedOption.label == "Выберите метрику" || selectedOption.label == "---") {
      setMetrika("clicks")
      setSelectedOptionMetrics({value: 'clicks', label: 'Выберите Метрику'})
      localStorage.setItem('profileMetricsValue', "clicks")
      localStorage.setItem('profileMetricsLabel', "Выберите метрику")
    } else {
      setMetrika(selectedOption.value)
      setSelectedOptionMetrics(selectedOption)
      localStorage.setItem('profileMetricsValue', `${selectedOption.value}`)
      localStorage.setItem('profileMetricsLabel', `${selectedOption.label}`)
      setButtonResetFilters(false)
      //checkSendReport()

      // sendMetricGoalVisitParametr('reachGoal', 'filter_goals_report-page', {
      //   [`${localStorage.getItem('profileName')}`]: {
      //     [`${window.location.pathname}`]: {
      //       "filter_goals_report-page": {}
      //     }
      //   }
      // })
    }
  }

  const handleAutotarget = (selectedOption) => {
    if (selectedOption === null || selectedOption.label == "Выберите условие показа" || selectedOption.label == "---") {
      setAutotarget("keywords")
      setSelectedOptionAutotarget({value: 'keywords', label: 'Выберите условие показа'})
      localStorage.setItem('profileAutotargetValue', "keywords")
      localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
    } else {
      setAutotarget(selectedOption.value)
      setSelectedOptionAutotarget(selectedOption)
      localStorage.setItem('profileAutotargetValue', `${selectedOption.value}`)
      localStorage.setItem('profileAutotargetLabel', `${selectedOption.label}`)
      setButtonResetFilters(false)
      //checkSendReport()

      // sendMetricGoalVisitParametr('reachGoal', 'filter_goals_report-page', {
      //   [`${localStorage.getItem('profileName')}`]: {
      //     [`${window.location.pathname}`]: {
      //       "filter_goals_report-page": {}
      //     }
      //   }
      // })
    }
  }

  // const checkButtonSendReport = () => {
  //   if (dateForReport && attributionForReport && goalForReport) {
  //     setButtonSendReport(false)
  //   } else {
  //     setButtonSendReport(true)
  //   }
  // }

  const checkSendReport = () => {
    if (dateForReport) {
      setButtonSendReport(false)

      if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
        setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
        setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
      }

      getSummaryReport()
      setMinDate(availableStartDate)
      setMaxDate(nowDate)
    } else {
      setButtonSendReport(true)
      if (startDate === null || endDate === null) {
        ///
      } else {
        getSummaryReport()
        setMinDate(availableStartDate)
        setMaxDate(nowDate)
      }
    }
  }

  const firstUpdate = useRef(true)

  async function fetchDate() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    await fetch(`${API_URL}/get_dataset_period/`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        if (json.status == "ERROR") {
          ///
        } else if (json.result.date_from == null || json.result.date_to == null) {
          ///
        } else if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
          setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
          setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
          const dateFromResult = new Date(json.result.date_from)
          const dateToResult = new Date(json.result.date_to)
          setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAutotargetDateFrom(new Date(json.result.autotarget_date_from))
          setAutotargetDateTo(new Date(json.result.autotarget_date_to))
          localStorage.setItem('reportDateTo', `${dateToResult.toLocaleDateString()}`)
          window.dispatchEvent(new Event("reportDateTo"))
        } else {
          const dateFromResult = new Date(json.result.date_from)
          const dateToResult = new Date(json.result.date_to)
          const lastDateFromResult = new Date(json.result.last_date_from)
          const lastDateToResult = new Date(json.result.last_date_to)
          setStartDate(new Date(lastDateFromResult.getFullYear(), lastDateFromResult.getMonth(), lastDateFromResult.getDate()))
          setEndDate(new Date(lastDateToResult.getFullYear(), lastDateToResult.getMonth(), lastDateToResult.getDate()))
          //setStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          //setEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAutotargetDateFrom(new Date(json.result.autotarget_date_from))
          setAutotargetDateTo(new Date(json.result.autotarget_date_to))
          localStorage.setItem('reportDateTo', `${dateToResult.toLocaleDateString()}`)
          window.dispatchEvent(new Event("reportDateTo"))
        }
      });
    firstUpdate.current = false
  }

  useEffect( () => {
    //console.log(dateForReport, attributionForReport, goalForReport)
    //checkButtonSendReport()

    if (!firstUpdate.current) {
      //console.log("firstUpdate: false")
      checkSendReport()
    }
  }, [dateForReport, metrika, autotarget, profileId, availableEndDate])

  useEffect( () => {
    setLoadingData(true)

    sendMetricVisitParametr("params", {
      [`${localStorage.getItem('profileName')}`]: {
        [`${window.location.pathname}`]: {}
      }
    })

    addToDataLayer({
      'login': currentUser?.email,
      'profile': localStorage.getItem('profileName'),
      'url': window.location.pathname,
    })

    if (localStorage.getItem("profileDateFrom") === null || localStorage.getItem("profileDateTo") === null) {
      localStorage.setItem('profileDateFrom', "0000-00-00")
      localStorage.setItem('profileDateTo', "0000-00-00")
      window.location.reload()
    }
    if (localStorage.getItem("reportDateTo") === null) {
      localStorage.setItem('reportDateTo', "0000-00-00")
      window.location.reload()
    }
    if (localStorage.getItem("profileAttributionValue") === null || localStorage.getItem("profileAttributionLabel") === null) {
      localStorage.setItem('profileAttributionValue', "8")
      localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
      window.location.reload()
    }
    if (localStorage.getItem("profileGoalValue") === null || localStorage.getItem("profileGoalLabel") === null) {
      localStorage.setItem('profileGoalValue', "0")
      localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
      window.location.reload()
    }
    if (localStorage.getItem("profileMetricsValue") === null || localStorage.getItem("profileMetricsLabel") === null) {
      localStorage.setItem('profileMetricsValue', "clicks")
      localStorage.setItem('profileMetricsLabel', "Выберите метрику")
      window.location.reload()
    }
    if (localStorage.getItem("profileAutotargetValue") === null || localStorage.getItem("profileAutotargetLabel") === null) {
      localStorage.setItem('profileAutotargetValue', "keywords")
      localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsYandexValue") === null || localStorage.getItem("profileUrlIsYandexLabel") === null) {
      localStorage.setItem('profileUrlIsYandexValue', "null")
      localStorage.setItem('profileUrlIsYandexLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsGoogleValue") === null || localStorage.getItem("profileUrlIsGoogleLabel") === null) {
      localStorage.setItem('profileUrlIsGoogleValue', "null")
      localStorage.setItem('profileUrlIsGoogleLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("checkDateValue") === null || localStorage.getItem("checkDateLabel") === null) {
      localStorage.setItem('checkDateValue', "0")
      localStorage.setItem('checkDateLabel', "Выберите дату")
      window.location.reload()
    }

    window.addEventListener('localStorage', () => {
      setProfileId(String(localStorage.getItem('profileId')))
      //setProfileDateFrom(String(localStorage.getItem('profileDateFrom')))
      //setProfileDateTo(String(localStorage.getItem('profileDateTo')))
    })

    setSelectedOptionAutotarget(defaultValueAutotarget)
    setSelectedOptionMetrics(defaultValueMetrics)

    fetchDate()
  },[])

  const [summaryReportRow, setSummaryReportRow] = useState<SRDataItem[]>([]);
  const [summaryReportRowSelect, setSummaryReportRowSelect] = useState<SRDataItem[]>([]);
  const [pageCountSummaryReport, setPageCountSummaryReport] = useState(0)

  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)

  const [searchValueSummaryReport, setSearchValueSummaryReport] = useState<String[]>([])
  const [filteringSummaryReport, setFilteringSummaryReport] = useState(false)

  const autotargets = [
    {value: "autotarget", label: "Автотаргетинг"},
    {value: "keywords", label: "Ключевые фразы"},
    {value: "summary", label: "Все вместе"}
  ]

  const metrics = [
    {value: "impressions", label: "Показы"},
    {value: "clicks", label: "Клики"},
    {value: "rate", label: "Расход"}
  ]

  const handleSearchAccount = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueSummaryReport([])
      setSelectedOptionSearchSummaryReport([])
      setFilteringSummaryReport(false)
    } else {
      setSearchValueSummaryReport([])
      setSelectedOptionSearchSummaryReport([])
      selectedOption.map(o =>
        setSearchValueSummaryReport(searchValueCampaign => [
          ...searchValueCampaign,
          o.profile_name
        ])
      )
      selectedOption.map(summaryReportItem =>
        setSelectedOptionSearchSummaryReport(searchValueAccount => [
          ...searchValueAccount,
          {
            profile_name: summaryReportItem.profile_name,
            update_date: summaryReportItem.update_date,
            kpss_from_impressions: summaryReportItem.kpss_from_impressions || null,
            kpss_from_clicks: summaryReportItem.kpss_from_clicks,
            rel_title_from_impressions: summaryReportItem.rel_title_from_impressions,
            rel_title_from_clicks: summaryReportItem.rel_title_from_clicks,
            rel_text_from_impressions: summaryReportItem.rel_text_from_impressions,
            rel_text_from_clicks: summaryReportItem.rel_text_from_clicks,
            rel_yandex_from_clicks: summaryReportItem.rel_yandex_from_clicks,
            rel_google_from_clicks: summaryReportItem.rel_google_from_clicks,
            autotarget_rate_part: summaryReportItem.autotarget_rate_part,
            search_autotarget: summaryReportItem.search_autotarget,
            search_other: summaryReportItem.search_other,
            network: summaryReportItem.network,
            value: `${summaryReportItem.profile_name}`,
            label: `${summaryReportItem.profile_name}`
          }
        ])
      )

      setFilteringSummaryReport(true)
      setButtonResetFilters(false)

      sendMetricGoalVisitParametr('reachGoal', 'filter_login_summary-report', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_login_summary-report": {}
          }
        }
      })
    }
  }

  const getSummaryReport = () => {
    if (localStorage.getItem('profileAutotargetValue') !== null || String(localStorage.getItem('profileGoalValue')) != "") {
      setAutotarget(String(localStorage.getItem('profileAutotargetValue')))
    }

    if (localStorage.getItem('profileMetricsValue') !== null || String(localStorage.getItem('profileGoalValue')) != "") {
      setMetrika(String(localStorage.getItem('profileMetricsValue')))
    }

    const profileId = localStorage.getItem('profileId')
    setLoading(true)
    setLoadingData(true)

    const querySummaryReport = {
      //profile_id: profileId,
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      display_condition: autotarget,
      metrika: metrika
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    fetch(`${API_URL}/get_accounts_summary/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(querySummaryReport)
    })
      .then(response => response.json())
      //.then(data => setKpssReportRow(data.data))
      .then((data) => {
        setLoading(false)
        setSummaryReportRow([])
        setSummaryReportRowSelect([])
        setPageCountSummaryReport(data.total)
        data.results.map(summaryReportItem => {
          setSummaryReportRow(summaryReportRow => [
            ...summaryReportRow,
            {
              profile_name: summaryReportItem.profile_name,
              update_date: summaryReportItem.update_date,
              kpss_from_clicks: summaryReportItem.kpss_from_clicks,
              kpss_from_impressions: summaryReportItem.kpss_from_impressions || null,
              rel_title_from_impressions: summaryReportItem.rel_title_from_impressions,
              rel_title_from_clicks: summaryReportItem.rel_title_from_clicks,
              rel_text_from_impressions: summaryReportItem.rel_text_from_impressions,
              rel_text_from_clicks: summaryReportItem.rel_text_from_clicks,
              rel_yandex_from_clicks: summaryReportItem.rel_yandex_from_clicks,
              rel_google_from_clicks: summaryReportItem.rel_google_from_clicks,
              autotarget_rate_part: summaryReportItem.autotarget_rate_part,
              search_autotarget: summaryReportItem.search_autotarget,
              search_other: summaryReportItem.search_other,
              network: summaryReportItem.network,
              value: `${summaryReportItem.profile_name}`,
              label: `${summaryReportItem.profile_name}`
            }
          ])

          setSummaryReportRowSelect(summaryReportRowSelect => [
            ...summaryReportRowSelect,
            {
              profile_name: summaryReportItem.profile_name,
              update_date: summaryReportItem.update_date,
              kpss_from_clicks: summaryReportItem.kpss_from_clicks,
              kpss_from_impressions: summaryReportItem.kpss_from_impressions,
              rel_title_from_impressions: summaryReportItem.rel_title_from_impressions,
              rel_title_from_clicks: summaryReportItem.rel_title_from_clicks,
              rel_text_from_impressions: summaryReportItem.rel_text_from_impressions,
              rel_text_from_clicks: summaryReportItem.rel_text_from_clicks,
              rel_yandex_from_clicks: summaryReportItem.rel_yandex_from_clicks,
              rel_google_from_clicks: summaryReportItem.rel_google_from_clicks,
              autotarget_rate_part: summaryReportItem.autotarget_rate_part,
              search_autotarget: summaryReportItem.search_autotarget,
              search_other: summaryReportItem.search_other,
              network: summaryReportItem.network,
              value: `${summaryReportItem.profile_name}`,
              label: `${summaryReportItem.profile_name}`
            }
          ])
        });

        setLoadingData(false)
      });
  }

  const editedSummaryReportRow = summaryReportRow.filter(x => x.kpss_from_impressions != null)

  const currentSummaryReportRow = filteringSummaryReport ? editedSummaryReportRow.filter((item) => searchValueSummaryReport.includes(item.profile_name)) : editedSummaryReportRow

  const editedSummaryReportRowSelect = summaryReportRowSelect.filter(x => x.kpss_from_impressions != null)

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    //if (toogle == 1) {
      //console.log(campaignReportRow)
      const header = ['Аккаунт директа',
        'Дата обновления',
        'КПСС через клики',
        'КПСС через показы',
        'Рел. заголовков через показы',
        'Рел. заголовков через клики',
        'Поиск Автотаргетинг %',
        'Поиск Остальное %',
        'Сеть %',
        'Рел. текста через показы',
        'Рел. текста через клики',
        'Рел. лендингов по Яндекс',
        'Рел. лендингов по Google',
        // 'Доля расхода с автотаргетинга',
        ];
      // const campaignData = (campaignReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.campaigns
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(currentSummaryReportRow) //, {header}
      XLSX.utils.book_append_sheet(wb, ws, "RSummary")
      XLSX.writeFile(wb, "RSummary.xlsx")
    //}

    // sendMetricGoalVisitParametr('reachGoal', 'table_download_relevant-ads-page', {
    //   [`${localStorage.getItem('profileName')}`]: {
    //     [`${window.location.pathname}`]: {
    //       "table_download_relevant-ads-page": {}
    //     }
    //   }
    // })
  }

  const handleResetFilters = () => {
    setSelectedOptionSearchSummaryReport([])

    setFilteringSummaryReport(false)

    //setStartDate(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    //setEndDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), 0))
    //setMinDate(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    //setMaxDate(nowDate)
    //setAvailableStartDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
    //setAvailableEndDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1))
    localStorage.setItem('profileDateFrom', "0000-00-00")
    localStorage.setItem('profileDateTo', "0000-00-00")
    setAutotarget("keywords")
    localStorage.setItem('profileAutotargetValue', "keywords")
    localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
    setSelectedOptionAutotarget(defaultValueAutotarget)
    setMetrika("clicks")
    localStorage.setItem('profileMetricsValue', "clicks")
    localStorage.setItem('profileMetricsLabel', "Выберите метрику")
    setSelectedOptionMetrics(defaultValueMetrics)

    setButtonResetFilters(true)

    fetchDate()
  }

  const [defaultFilterValue, setDefaultFilterValue] = useState([
    { name: 'kpss_from_impressions', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'kpss_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'rel_title_from_impressions', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'rel_title_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'search_autotarget', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'search_other', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'network', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'rel_yandex_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'rel_google_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null }
  ])

  const onFilterValueChange = useCallback((filterValue) => {
    setDefaultFilterValue(filterValue)
  }, [])

  const metricStyles = {
    control: (base, state) => ({
      ...base,
      background: "rgb(252 255 136 / 20%)",
      // // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // // Removes weird border around container
      // boxShadow: state.isFocused ? null : null,
      // "&:hover": {
      //   // Overwrittes the different states of border
      //   borderColor: state.isFocused ? "red" : "blue"
      // }
    }),
    // menu: base => ({
    //   ...base,
    //   // override border radius to match the box
    //   borderRadius: 0,
    //   // kill the gap
    //   marginTop: 0
    // }),
    // menuList: base => ({
    //   ...base,
    //   // kill the white space on first and last option
    //   padding: 0,
    //   background: "rgb(252 255 136 / 20%)"
    // })
  };

  return (
    <div>
      {/* <div>{JSON.stringify(kpssReportRow)}</div> */}
      <div className='card-container'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex flex-column'>
          <ol className="breadcrumb breadcrumb-dot text-muted fs-7 fw-bold mb-5">
            <li className="breadcrumb-item pe-3">

              <a href="#" className="pe-1">
                <i className="fa-solid fa-house text-muted fs-4" />
              </a>
            </li>
            <li className="breadcrumb-item px-0 text-muted">Отчет по аккаунтам</li>
          </ol>
          <div className='row d-flex justify-content-between'>
            <div className='col'>
              <h2 className='card-title align-items-start flex-column mb-3'>
                <span className='card-label fw-bold fs-3'>Отчет по аккаунтам </span>
              </h2>
              <h5 className='mb-2 text-muted'>Сводный отчет по всем доступным аккаунтам</h5>
            </div>
            <div className='col d-flex justify-content-end'>
              <button className='btn btn-light-primary fw-semibold' onClick={handleOnExport}>Выгрузить в XLSX
                <KTIcon iconName='arrow-down-right' className='fs-4  ms-1' />
              </button>

              <button
                className={`btn fw-semibold btn-light-primary ${buttonResetFilters ? "btn-reset-disabled" : "btn-reset"}`}
                onClick={handleResetFilters}
                disabled={buttonResetFilters}
              >
                Сбросить фильтры
              </button>
            </div>
            <div className='row'><br/></div>
          </div>

          {/*<ul className="nav nav-tabs nav-line-tabs mb-2 mt-5 fs-7 border-bottom-0">*/}
          {/*  <li className="nav-item">*/}
          {/*    <a*/}
          {/*      className={toogle === 1 ? "nav-link fw-bold active" : "nav-link fw-bold"}*/}
          {/*      //data-bs-toggle="tab"*/}
          {/*      //href="#kt_tab_pane_1"*/}
          {/*      href="#"*/}
          {/*      onClick={()=>updateToggle(1)}*/}
          {/*    >*/}
          {/*      Кампании*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li className="nav-item">*/}
          {/*    <a*/}
          {/*      className={toogle === 2 ? `nav-link fw-bold active` : `nav-link fw-bold ${isActiveGroups ? "disabled" : ""}`}*/}
          {/*      //data-bs-toggle="tab"*/}
          {/*      //href="#kt_tab_pane_2"*/}
          {/*      href="#"*/}
          {/*      onClick={()=>updateToggle(2)}*/}
          {/*    >*/}
          {/*      Группы*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li className="nav-item">*/}
          {/*    <a*/}
          {/*      className={toogle === 3 ? `nav-link fw-bold active` : `nav-link fw-bold ${isActiveAdvert ? "disabled" : ""}`}*/}
          {/*      //data-bs-toggle="tab"*/}
          {/*      //href="#kt_tab_pane_3"*/}
          {/*      href="#"*/}
          {/*      onClick={()=>updateToggle(3)}*/}
          {/*    >*/}
          {/*      Объявления*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
        {/* end::Header */}


        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel">
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-2 mb-md-5 mb-xl-6'>
                </div>
                <div className='col-4 mb-md-5 mb-xl-6 text-period'>
                  Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""} | {autotargetDateFrom.toLocaleDateString() == new Date(0).toLocaleDateString() ? "Автотаргетинг в НГРАМ отсутствует" : `Автотаргетинг с ${autotargetDateFrom.toLocaleDateString()}`}
                </div>
                <div className='col-3 mb-md-5 mb-xl-6'>
                </div>
                <div className='col-3 mb-md-5 mb-xl-6'>
                </div>
              </div>
              <div className='row'>
                <div className='col-2 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                                            <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                                <i className="bi bi-search fs-4 fw-bold" />
                                            </span>
                    <Select
                      className='react-select-container form-control border-start-0 p-0 my-search'
                      classNamePrefix="react-select"
                      options={editedSummaryReportRowSelect}
                      placeholder="Поиск по логину"
                      defaultValue={selectedOptionSearchSummaryReport}
                      value={selectedOptionSearchSummaryReport}
                      onChange={handleSearchAccount}
                      isMulti
                      onFocus={() => setSelectFocus(2)}
                      onBlur={onBlur}
                    />
                  </div>
                </div>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                                    <i className="bi bi-calendar4-event fs-7 fw-bold" />
                                </span>

                    <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                      //locale={locale}
                                locale="ru"
                                selected={startDate}
                                onChange={handleChangeDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                      //dateFormat="yyyy-MM-dd"
                                dateFormat="d MMMM yyyy"
                    />

                  </div>
                </div>
                <div className='col-3 mb-md-5 mb-xl-6' style={{marginTop: '-23px'}}>
                  <h6>Метрика по типу трафика
                    {/*<Tooltip text="По умолчанию используется 'Ключевые фразы'">*/}
                    {/*  <i className="las la-info-circle ms-2 fs-2" />*/}
                    {/*</Tooltip>*/}
                  </h6>
                  <Select styles={metricStyles} options={metrics} className="form-select react-select-container p-0 my-select-metrics" classNamePrefix="no-icon" placeholder="Выберите метрику" value={selectedOptionMetrics} defaultValue={defaultValueMetrics} onChange={handleMetrics} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-3 mb-md-5 mb-xl-6' style={{marginTop: '-23px'}}>
                  <h6>Условия показа
                    <Tooltip text="По умолчанию используется 'Ключевые фразы'">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={autotargets} className="form-select react-select-container p-0 my-select-autotarget" classNamePrefix="no-icon" placeholder="Выберите условие показа" value={selectedOptionAutotarget} defaultValue={defaultValueAutotarget} onChange={handleAutotarget} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
              </div>
              {/*<div className='row mb-5'>*/}
              {/*  <div className='col-4 mb-md-5 mb-xl-6'>*/}
              {/*    <h6>Атрибуция*/}
              {/*      <Tooltip text="По умолчанию используется Автоматическая атрибуция">*/}
              {/*        <i className="las la-info-circle ms-2 fs-2" />*/}
              {/*      </Tooltip>*/}
              {/*    </h6>*/}
              {/*    <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
              {/*  </div>*/}
              {/*  <div className='col-8 mb-md-5 mb-xl-6'>*/}
              {/*    <h6>Данные по целям*/}
              {/*      <Tooltip text="По умолчанию выводятся данные по целям из настроек РК">*/}
              {/*        <i className="las la-info-circle ms-2 fs-2" />*/}
              {/*      </Tooltip>*/}
              {/*    </h6>*/}
              {/*    <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon"  placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
              {/*  </div>*/}
              {/*</div>*/}


              {/*<div className='row mb-10'>*/}
              {/*  <div className='col-8'>*/}
              {/*    <div className='bg-light'>*/}
              {/*      <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>*/}
              {/*        /!* begin::Table head *!/*/}
              {/*        <thead>*/}
              {/*        <tr className='fw-bold text-muted'>*/}
              {/*          <th className='min-w-160px text-nowrap'>*/}
              {/*            <div className='d-flex align-items-center justify-content-between'>*/}
              {/*              <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. заголовка</span>*/}
              {/*            </div>*/}
              {/*          </th>*/}
              {/*          <th className='min-w-160px'>*/}
              {/*            <div className='d-flex align-items-center justify-content-between'>*/}
              {/*              <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. заголовка</span>*/}
              {/*            </div>*/}
              {/*          </th>*/}
              {/*          <th className='min-w-160px text-nowrap text-muted fw-semibold'>*/}
              {/*            <div className='d-flex align-items-center justify-content-between'>*/}
              {/*              <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. текста</span>*/}
              {/*            </div>*/}
              {/*          </th>*/}
              {/*          <th className='min-w-160px text-nowrap text-muted fw-semibold'>*/}
              {/*            <div className='d-flex align-items-center justify-content-between'>*/}
              {/*              <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. текста</span>*/}
              {/*            </div>*/}
              {/*          </th>*/}
              {/*        </tr>*/}
              {/*        </thead>*/}
              {/*        /!* end::Table head *!/*/}
              {/*        /!* begin::Table body *!/*/}
              {/*        <tbody>*/}
              {/*        <tr>*/}
              {/*          <td>*/}
              {/*            <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
              {/*              /!*55,94%*!/*/}
              {/*              {summaryRelTitleFromImpressions}%*/}
              {/*            </div>*/}
              {/*          </td>*/}
              {/*          <td>*/}
              {/*            <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
              {/*              /!*60,97%*!/*/}
              {/*              {summaryRelTitleFromClicks}%*/}
              {/*            </div>*/}
              {/*          </td>*/}
              {/*          <td>*/}
              {/*            <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
              {/*              /!*26,97%*!/*/}
              {/*              {summaryRelTextFromImpressions}%*/}
              {/*            </div>*/}
              {/*          </td>*/}
              {/*          <td>*/}
              {/*            <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
              {/*              /!*32,08%*!/*/}
              {/*              {summaryRelTextFromClicks}%*/}
              {/*            </div>*/}
              {/*          </td>*/}
              {/*        </tr>*/}


              {/*        </tbody>*/}
              {/*        /!* end::Table body *!/*/}
              {/*      </table>*/}

              {/*    </div>*/}

              {/*  </div>*/}
              {/*</div>*/}
              {/* begin::Table container */}
              <div className=''>
                {/* begin::Table */}
                <ReactDataGrid
                  ///checkboxColumn={true}
                  ///checkboxColumn={checkboxColumn} // Object for disabled checkbox
                  checkboxOnlyRowSelect={true}
                  className='table__data-grid'
                  idProperty="campaign_id"
                  showZebraRows={false}
                  style={gridStyle}
                  activateRowOnFocus={activateRowOnFocus}
                  columns={columnsReport}
                  dataSource={currentSummaryReportRow}
                  pagination={true}
                  //limit={10}
                  defaultLimit={10}
                  rowHeight={null}
                  minRowHeight={63}
                  ///onSelectionChange={onSelectionCampaign}
                  ///selected={selectedCampaign}
                  editable={true}
                  loading={loadingData}
                  loadingText={pleaseWait}
                  enableFiltering={true}
                  defaultFilterValue={defaultFilterValue}
                  onFilterValueChange={onFilterValueChange}
                  i18n={translateRDG}
                  enableColumnAutosize={false}
                />
                {/* end::Table */}
              </div>
              {/* end::Table container */}

            </div>
            {/* begin::Body */}

          </div>
          {/*<div className={toogle === 2 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_2" role="tabpanel">*/}
          {/*  /!* begin::Body *!/*/}
          {/*  <div className='card-body py-3'>*/}
          {/*    <div className='row'>*/}
          {/*      <div className='col-6 mb-md-5 mb-xl-6'>*/}
          {/*      </div>*/}
          {/*      <div className='col-6 mb-md-5 mb-xl-6'>*/}
          {/*        Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='row'>*/}
          {/*      <div className='col-6 mb-md-5 mb-xl-6'>*/}
          {/*        <div className="input-group mb-3">*/}
          {/*                                  <span className="input-group-text bg-white border-end-0" id="basic-addon1">*/}
          {/*                                      <i className="bi bi-search fs-4 fw-bold" />*/}
          {/*                                  </span>*/}
          {/*          <Select options={adgroupReportRowSelect} className='react-select-container form-control border-start-0 p-0 my-search' classNamePrefix="react-select" placeholder="Поиск по группам" onChange={handleSearchAdgroup} isMulti onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className='col-6 mb-md-5 mb-xl-6'>*/}
          {/*        <div className="input-group mb-3">*/}
          {/*                      <span className="input-group-text bg-white border-end-0" id="basic-addon2">*/}
          {/*                          <i className="bi bi-calendar4-event fs-7 fw-bold" />*/}
          {/*                      </span>*/}

          {/*          <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"*/}
          {/*            //locale={locale}*/}
          {/*                      locale="ru"*/}
          {/*                      selected={startDate}*/}
          {/*                      onChange={handleChangeDate}*/}
          {/*                      minDate={minDate}*/}
          {/*                      maxDate={maxDate}*/}
          {/*                      selectsRange*/}
          {/*                      startDate={startDate}*/}
          {/*                      endDate={endDate}*/}
          {/*            //dateFormat="yyyy-MM-dd"*/}
          {/*                      dateFormat="d MMMM yyyy"*/}
          {/*          />*/}

          {/*        </div>*/}
          {/*      </div>*/}



          {/*    </div>*/}
          {/*    <div className='row mb-5'>*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}
          {/*        <h6>Атрибуция*/}
          {/*          <Tooltip text="По умолчанию используется Автоматическая атрибуция">*/}
          {/*            <i className="las la-info-circle ms-2 fs-2" />*/}
          {/*          </Tooltip>*/}
          {/*        </h6>*/}
          {/*        <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
          {/*      </div>*/}
          {/*      <div className='col-8 mb-md-5 mb-xl-6'>*/}
          {/*        <h6>Данные по целям*/}
          {/*          <Tooltip text="По умолчанию выводятся данные по целям из настроек РК">*/}
          {/*            <i className="las la-info-circle ms-2 fs-2" />*/}
          {/*          </Tooltip>*/}
          {/*        </h6>*/}
          {/*        <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
          {/*      </div>*/}
          {/*    </div>*/}


          {/*    <div className='row mb-10'>*/}
          {/*      <div className='col-8'>*/}
          {/*        <div className='bg-light'>*/}
          {/*          <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>*/}
          {/*            /!* begin::Table head *!/*/}
          {/*            <thead>*/}
          {/*            <tr className='fw-bold text-muted'>*/}
          {/*              <th className='min-w-160px text-nowrap'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. заголовка</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*              <th className='min-w-160px'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. заголовка</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*              <th className='min-w-160px text-nowrap text-muted fw-semibold'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. текста</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*              <th className='min-w-160px text-nowrap text-muted fw-semibold'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. текста</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*            </tr>*/}
          {/*            </thead>*/}
          {/*            /!* end::Table head *!/*/}
          {/*            /!* begin::Table body *!/*/}
          {/*            <tbody>*/}
          {/*            <tr>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*55,94%*!/*/}
          {/*                  {summaryRelTitleFromImpressions}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*60,97%*!/*/}
          {/*                  {summaryRelTitleFromClicks}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*26,97%*!/*/}
          {/*                  {summaryRelTextFromImpressions}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*32,08%*!/*/}
          {/*                  {summaryRelTextFromClicks}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*            </tr>*/}


          {/*            </tbody>*/}
          {/*            /!* end::Table body *!/*/}
          {/*          </table>*/}

          {/*        </div>*/}

          {/*      </div>*/}
          {/*    </div>*/}
          {/*    /!* begin::Table container *!/*/}
          {/*    <div className=''>*/}
          {/*      /!* begin::Table *!/*/}
          {/*      <ReactDataGrid*/}
          {/*        checkboxColumn={true}*/}
          {/*        checkboxOnlyRowSelect={true}*/}
          {/*        className='table__data-grid'*/}
          {/*        idProperty="adgroup_id"*/}
          {/*        showZebraRows={false}*/}
          {/*        style={gridStyle}*/}
          {/*        activateRowOnFocus={activateRowOnFocus}*/}
          {/*        columns={columnsAdgroup}*/}
          {/*        dataSource={currentAdgroupReportRow}*/}
          {/*        pagination={true}*/}
          {/*        //limit={10}*/}
          {/*        defaultLimit={10}*/}
          {/*        rowHeight={null}*/}
          {/*        minRowHeight={63}*/}
          {/*        onSelectionChange={onSelectionAdgroup}*/}
          {/*        selected={selectedAdgroup}*/}
          {/*        editable={true}*/}
          {/*        loading={loadingData}*/}
          {/*        loadingText={pleaseWait}*/}
          {/*      />*/}
          {/*      /!* end::Table *!/*/}
          {/*    </div>*/}
          {/*    /!* end::Table container *!/*/}

          {/*  </div>*/}
          {/*  /!* begin::Body *!/*/}

          {/*</div>*/}
          {/*<div className={toogle === 3 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_3" role="tabpanel">*/}
          {/*  /!* begin::Body *!/*/}
          {/*  <div className='card-body py-3'>*/}
          {/*    <div className='row'>*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}

          {/*      </div>*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}

          {/*      </div>*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}
          {/*        Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='row'>*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}
          {/*        <div className="input-group">*/}
          {/*                      <span className="input-group-text bg-white border-end-0" id="basic-addon1">*/}
          {/*                          <i className="bi bi-search fs-4 fw-bold" />*/}
          {/*                      </span>*/}
          {/*          <Select options={advertReportRowSelect} className='react-select-container form-control border-start-0 p-0 my-search' classNamePrefix="react-select" placeholder="Поиск по заголовкам" onChange={handleSearchAdvert} isMulti onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}
          {/*        <div className="input-group">*/}
          {/*                      <span className="input-group-text bg-white border-end-0" id="basic-addon1">*/}
          {/*                          <i className="bi bi-search fs-4 fw-bold" />*/}
          {/*                      </span>*/}
          {/*          <Select options={advertTextReportRowSelect} className='react-select-container form-control border-start-0 p-0 my-search' classNamePrefix="react-select" placeholder="Поиск по тексту" onChange={handleSearchAdvertText} isMulti onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}
          {/*        <div className="input-group mb-3">*/}
          {/*                      <span className="input-group-text bg-white border-end-0" id="basic-addon2">*/}
          {/*                        <i className="bi bi-calendar4-event fs-7 fw-bold" />*/}
          {/*                      </span>*/}

          {/*          <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"*/}
          {/*            //locale={locale}*/}
          {/*                      locale="ru"*/}
          {/*                      selected={startDate}*/}
          {/*                      onChange={handleChangeDate}*/}
          {/*                      minDate={minDate}*/}
          {/*                      maxDate={maxDate}*/}
          {/*                      selectsRange*/}
          {/*                      startDate={startDate}*/}
          {/*                      endDate={endDate}*/}
          {/*            //dateFormat="yyyy-MM-dd"*/}
          {/*                      dateFormat="d MMMM yyyy"*/}
          {/*          />*/}

          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='row'>*/}
          {/*      /!*<div className='col-4 mb-md-5 mb-xl-6'>*!/*/}
          {/*      /!*    <h6>Период*!/*/}
          {/*      /!*    </h6>*!/*/}
          {/*      /!*    <div className="input-group">*!/*/}
          {/*      /!*    <span className="input-group-text bg-white border-end-0" id="basic-addon2">*!/*/}
          {/*      /!*            <i className="bi bi-calendar4-event fs-7 fw-bold" />*!/*/}
          {/*      /!*        </span>*!/*/}
          {/*      /!*        <Select options={options} className='react-select-container form-control border-start-0 p-0 my-select' classNamePrefix="react-select" placeholder="16 декабря 2023г ???" onChange={handleSearchCampaign}/>*!/*/}
          {/*      /!*    </div>*!/*/}
          {/*      /!*</div>*!/*/}
          {/*      <div className='col-4 mb-md-5 mb-xl-6'>*/}
          {/*        <h6>Атрибуция*/}
          {/*          <Tooltip text="По умолчанию используется Автоматическая атрибуция">*/}
          {/*            <i className="las la-info-circle ms-2 fs-2" />*/}
          {/*          </Tooltip>*/}
          {/*        </h6>*/}
          {/*        <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
          {/*      </div>*/}
          {/*      <div className='col-8 mb-md-5 mb-xl-6'>*/}
          {/*        <h6>Данные по целям*/}
          {/*          <Tooltip text="По умолчанию выводятся данные по целям из настроек РК">*/}
          {/*            <i className="las la-info-circle ms-2 fs-2" />*/}
          {/*          </Tooltip>*/}
          {/*        </h6>*/}
          {/*        <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <div className='row mb-10'>*/}
          {/*      <div className='col-8'>*/}
          {/*        <div className='bg-light'>*/}
          {/*          <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>*/}
          {/*            /!* begin::Table head *!/*/}
          {/*            <thead>*/}
          {/*            <tr className='fw-bold text-muted'>*/}
          {/*              <th className='min-w-160px text-nowrap'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. заголовка</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*              <th className='min-w-160px'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. заголовка</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*              <th className='min-w-160px text-nowrap text-muted fw-semibold'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. текста</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*              <th className='min-w-160px text-nowrap text-muted fw-semibold'>*/}
          {/*                <div className='d-flex align-items-center justify-content-between'>*/}
          {/*                  <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. текста</span>*/}
          {/*                </div>*/}
          {/*              </th>*/}
          {/*            </tr>*/}
          {/*            </thead>*/}
          {/*            /!* end::Table head *!/*/}
          {/*            /!* begin::Table body *!/*/}
          {/*            <tbody>*/}
          {/*            <tr>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*55,94%*!/*/}
          {/*                  {summaryRelTitleFromImpressions}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*60,97%*!/*/}
          {/*                  {summaryRelTitleFromClicks}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*26,97%*!/*/}
          {/*                  {summaryRelTextFromImpressions}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*              <td>*/}
          {/*                <div className='d-flex align-items-center text-dark fw-bold fs-7'>*/}
          {/*                  /!*32,08%*!/*/}
          {/*                  {summaryRelTextFromClicks}%*/}
          {/*                </div>*/}
          {/*              </td>*/}
          {/*            </tr>*/}


          {/*            </tbody>*/}
          {/*            /!* end::Table body *!/*/}
          {/*          </table>*/}

          {/*        </div>*/}

          {/*      </div>*/}
          {/*    </div>*/}
          {/*    /!* begin::Table container *!/*/}
          {/*    <div className=''>*/}
          {/*      /!* begin::Table *!/*/}
          {/*      <ReactDataGrid*/}
          {/*        idProperty="id"*/}
          {/*        showZebraRows={false}*/}
          {/*        style={gridStyle}*/}
          {/*        activateRowOnFocus={activateRowOnFocus}*/}
          {/*        columns={columnsAdvert}*/}
          {/*        dataSource={currentAdvertReportRow()}*/}
          {/*        pagination={true}*/}
          {/*        //limit={10}*/}
          {/*        defaultLimit={10}*/}
          {/*        rowHeight={null}*/}
          {/*        minRowHeight={63}*/}
          {/*        editable={true}*/}
          {/*        loading={loadingData}*/}
          {/*        loadingText={pleaseWait}*/}
          {/*      />*/}
          {/*      /!* end::Table *!/*/}
          {/*    </div>*/}
          {/*    /!* end::Table container *!/*/}

          {/*  </div>*/}
          {/*  /!* begin::Body *!/*/}

          {/*</div>*/}
        </div>




      </div>






      <div className="modal fade" tabIndex={-1} id="kt_modal_3">
        <div className="modal-dialog alert p-0">
          <div className="modal-content p-10">
            <h3 className="modal-title text-center fw-bold text-middle mb-4">Расчет ставок может занять до 5 минут</h3>
            <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
              <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3" />

              <div className="d-flex flex-column align-items-center">
                <h5 className="text-center fw-semibold">Пожалуйста, не закрывайте окно N-Gram Bidder</h5>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-primary w-100"
              data-bs-dismiss="modal"
            >
              Ок
            </button>
          </div>
        </div>
      </div>


      <div className="modal fade" tabIndex={-1} id="kt_modal_6">
        <div className="modal-dialog alert p-0">
          <div className="modal-content p-10">
            <h3 className="modal-title text-center fw-bold text-middle mb-4">Ставки установлены</h3>
            <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
              <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3" />

              <div className="d-flex flex-column align-items-center">
                <h5 className="text-center fw-semibold">Не забудьте отправить изменения в Яндекс.Директ</h5>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-primary w-100"
              data-bs-dismiss="modal"
            >
              Хорошо
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TablesWidget19}
