import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {KTSVG} from '../../../../helpers'
import {KTIcon, toAbsoluteUrl} from '../../../../helpers'
import {SidebarLogo} from '../../sidebar/SidebarLogo'
import React, {useEffect, useRef, useState} from 'react'

export function MenuInner() {
  const intl = useIntl()
  const date = new Date(new Date().setDate(new Date().getDate()-1)).toLocaleDateString()
  //const day = <div className='fs-6 fw-semibold text-middle ms-10 day-update'>Данные обновлены на {`${date.toLocaleDateString()}`} г</div>

  const [reportDateTo, setReportDateTo] = useState<string>(date)
  const [landingDateTo, setLandingDateTo] = useState<string>("")

  const sidebarRef = useRef<HTMLDivElement>(null)

  useEffect( () => {
    window.addEventListener('reportDateTo', () => {
      setReportDateTo(String(localStorage.getItem('reportDateTo')))
      setLandingDateTo(String(localStorage.getItem('checkDateLabel')).slice(13, 23))
    })
  }, [])

  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Layout Builder' to='/builder' /> */}

      <SidebarLogo sidebarRef={sidebarRef} />

      <div className='menu-item'>
        {/*{day}*/}
        {(() => {
          if (window.location.pathname === '/relevant-page/' && localStorage.getItem('checkDateValue') == "0") {
            return (
              <div className='fs-6 fw-semibold text-middle ms-10 day-update'> </div>
            )
          } else if (window.location.pathname === '/relevant-page/' && localStorage.getItem('checkDateValue') != "0") {
            return (
              <div className='fs-6 fw-semibold text-middle ms-10 day-update'>Статистика по {landingDateTo} включительно</div>
            )
          } else {
            return (
              <div className='fs-6 fw-semibold text-middle ms-10 day-update'>Статистика по {reportDateTo} включительно</div>
            )
          }
        })()}
      </div>

      {/* <div className='menu-item'>
      <button type='button' className='me-4 btn btn-light-primary rounded-2'
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_4"
          >
          Получить данные
          <KTIcon iconName='arrow-down-right' className='fs-4  ms-1' />
      </button>
      </div>

      <div className='menu-item'>
        <button type='button' className='me-4 btn btn-primary rounded-2'
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_5"
            >
            Отправить данные
          <KTIcon iconName='arrow-up-right' className='fs-4 ms-1' />
        </button>
      </div>

      <div className='menu-item flex-column align-items-start justify-content-center me-lg-1'>
        <div className='text-dark fw-bold fs-6'>Есть изменения</div>
        <div>Отправьте данные на сервер</div>
      </div> */}
    </>
  )
}
